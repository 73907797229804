import React from 'react';
import styled from "@emotion/styled";
import '../../../../assets/fonts/fonts.css';
import mediaqueries from "@styles/media";

const HOLogo = styled.h3`
  word-break: keep-all;
  font-size: 24px;
  line-height: 1.45;
  font-weight: 800;
  color: #335eea;
  font-family: HKGroteskPro;
  ${mediaqueries.tablet`
    font-size: 22px;
  `};
  ${mediaqueries.phablet`
    font-size: 20px;
  `};
`;

export default function Logo({ fill }) {
  return (
    <HOLogo>Heavenly Organised</HOLogo>
  );
}